import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyFacturacionCheckout: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Facturación en Checkout
            </h2>
            <p>
                Cuando un cliente realiza un pedido en linea, en la <b>sección de agradecimiento</b> se mostrará una forma para que tu cliente solicite su factura.
            </p>
            <p>
                Los pedidos que ya han sido pagados, recibirán su factura inmediatamente, <b>los pedidos que no se hayan pagado en este momento</b> tales como OXXO, 7Eleven, SPEI, etc. recibirán la factura 
                hasta que se confirme el pago, solo recibirán una confirmación mencionando que recibirán la factura cuando se confirme el pago. 
            </p>
            <ImageFooted explanation={`La imagen superior muestra el bloque para facturar pedidos en línea.`}>
                <IMG_Shopify file={'onCheckout'} />
            </ImageFooted>
            <p>
                <b>La sección de facturación en Checkout se crea automáticamente</b> y no necesita configuración alguna. <br />
                Si por alguna razón quisieras desactivarla, escríbenos a <a href="mailto:soporte@fiscalpop.com">soporte@fiscalpop.com</a> 
            </p>
            <SectionNavigate previous={`/ecommerce/shopify/agregar-pagina-facturacion`} next={`/ecommerce/shopify/post-facturacion`}/>
        </div>
    </Layout>
)

export default ShopifyFacturacionCheckout;